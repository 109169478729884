<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:
                        v-select.mb-3(
                            v-if="$root.profile.type == 0 || $root.profile.type == 2"
                            v-model="filter.network"
                            :items='networks'
                            item-text="name"
                            item-value="uuid"
                            label='Red'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            :filter="vFilter"
                            dark
                            color="white"
                        )
                        v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                            v-text-field(slot='activator' :label="$t('Desde')" v-model='filter.from' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateStart = false') {{ $t('Cancelar') }}
                                v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{ $t('OK') }}
                        v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                            v-text-field(slot='activator' :label="$t('Hasta')" v-model='filter.to' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{ $t('Cancelar') }}
                                v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{ $t('OK') }}
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{ $t('Limpiar') }}
            v-content
                v-snackbar(
                :timeout="30000",
                :top="true"
                :right="true"
                :color="alertColor"
                v-model="alertMsj")
                    | {{ alertText }}
                    v-btn(flat, dark, @click.native="alertMsj = false") {{ $t('cerrar') }}
                v-container.grid-list-xl(fluid)
                    h1 {{ $t('Encuestas') }}
                    template(v-if="preload")
                        h3 {{ $t('Cargando...') }}
                    template(v-else)
                        v-layout(row wrap v-if="surveys.length")
                            v-flex(xs12 sm6 md4 lg3 xl2 v-for="survey in surveys" :key="survey.uuid")
                                .sheet.grey.lighten-3
                                    .sheet-options
                                        v-menu(left)
                                            v-icon(color='primary' dark slot='activator') more_vert
                                            v-list.sheet-menu
                                                v-list-tile.white--text(@click="open(survey)")
                                                    v-list-tile-title {{ $t('Editar') }}
                                                v-list-tile.white--text(@click="openShare(survey)")
                                                    v-list-tile-title {{ $t('Compartir') }}
                                                v-list-tile.white--text(@click="$router.push({path:'/survey/' + survey.uuid})")
                                                    v-list-tile-title {{ $t('Resultados') }}
                                                v-list-tile.white--text(v-if="profile.type == 0" @click="openImport(survey)")
                                                    v-list-tile-title {{ $t('Importar contactos') }}
                                    .sheet-title
                                        h2.primary--text {{ survey.name }}
                                    .sheet-content
                                        p {{ survey.network }}
                                        b: small {{ $t('Alias') }}: {{ survey.alias }}
                                        small.d-block {{ $t('Estado') }}: {{ $t(survey.status ? 'Habilitada' : 'Inhabilitada') }}
                            v-flex(v-if="surveys.length" xs12)
                                infinite-loading(:infinite="infinite" ref="infinite")
                        .not-found(v-else)
                            h3 {{ $t('No se encontraron encuestas registradas') }}
                    v-btn(@click="open(surveySchema)" fixed dark fab bottom right color='primary')
                        v-icon add
                    v-dialog(v-model="modal" max-width="1024" persistent)
                        v-form
                            v-card
                                v-card-title {{ $t('Encuesta') }}
                                v-card-text
                                    template(v-if="loadModal")
                                        h3 {{ $t('Cargando...') }}
                                    template(v-else)
                                        v-layout(row wrap)
                                            v-flex(xs12 v-if="$root.profile.type == 0 || $root.profile.type == 2")
                                                v-select.mb-3(
                                                    v-model="model.networkid"
                                                    :items='networks'
                                                    item-text="name"
                                                    item-value="uuid"
                                                    label='Red'
                                                    append-icon="keyboard_arrow_down" 
                                                    autocomplete
                                                    :filter="vFilter"
                                                )
                                            v-flex(xs12 sm6)
                                                v-text-field(:label="$t('Nombre')" v-model="model.name")
                                            v-flex(xs12 sm6)
                                                v-text-field(:label="$t('Alias')" v-model="model.alias")
                                            v-flex(xs12 sm12)
                                                v-text-field(:label="$t('Texto inicial')" multi-line v-model="model.starttext")
                                            v-flex(xs12 sm12)
                                                v-text-field(:label="$t('Texto final')" multi-line v-model="model.endtext")
                                            v-flex(xs12 sm12)
                                                h4.my-2 {{$t('Términos y condiciones')}}
                                                editor(:init="$root.editor" v-model="model.terms")
                                            v-flex(xs12 sm12)
                                                h4.my-2 {{$t('Política de privacidad')}}
                                                editor(:init="$root.editor" v-model="model.priv")
                                            v-flex(xs12)
                                                label {{$t('Habilitado')}}
                                                v-divider.mt-2
                                                .switch-group
                                                    label {{$t('No')}}
                                                    v-switch(:label="$t('Si')" color="success" v-model="model.status")
                                            v-flex(xs12)
                                                label {{$t('Confirmar WhatsApp')}}
                                                v-divider.mt-2
                                                .switch-group
                                                    label {{$t('No')}}
                                                    v-switch(:label="$t('Si')" color="success" v-model="model.wpconfirm")
                                            v-flex(xs12 sm12 v-if="model.wpconfirm")
                                                v-text-field(:label="$t('WhatsApp')" v-model="model.whatsapp")
                                            v-flex.mt-2(xs12 sm6)
                                                h3.mb-3 {{$t('Foto para inicio y compartir')}}
                                                v-btn.btn-file(color="success" outline block large) 
                                                    v-icon(left) file_upload
                                                    | {{$t('Subir foto')}}
                                                    input(type='file' @change='fileChange($event, "shareImg", "share")')
                                                .img-file(v-if="model.shareImg.length")
                                                    img(:src="model.shareImg")
                                                    v-icon(@click='removeImage("shareImg", "share")') close
                                                .img-file(v-else)
                                                    img(:src="model.share ? uploadurl + model.share + '?time=' + model.time : imgurl")
                                            template(v-if="node.length")
                                                v-flex.mt-3(xs12)
                                                    h3 {{$t('Disponibilidad regional de la encuesta')}}
                                                v-flex(xs12 sm6)
                                                    v-btn.primary(small @click="selectAll") {{ $t('Seleccionar todos') }}
                                                    v-btn.primary(small @click="unselectAll") {{ $t('Deseleccionar todos') }}
                                                    liquor-tree(
                                                        ref="treeNode"
                                                        :data="node"
                                                        :options="tree"
                                                        style="max-height:300px;"
                                                    )
                                            v-flex(xs12 v-if="$root.profile.type == 0")
                                                label {{$t('Habilitar captcha')}}
                                                v-divider.mt-2
                                                .switch-group
                                                    label {{$t('No')}}
                                                    v-switch(:label="$t('Si')" color="success" v-model="model.recaptcha")
                                            template
                                                v-flex.mt-3(xs12)
                                                    h3 {{ $t('Subadministradores de la encuesta') }}
                                                v-flex(xs12 v-for="(email, i) in model.email" :key="'email' + i")
                                                    v-text-field(browser-autocomplete='new-password' label="Email" v-model="model.email[i]" append-icon="close" :append-icon-cb="() => deleteEmail(i)")
                                                v-flex(xs12)
                                                    v-btn.primary.mt-2.mx-0(@click="model.email.push('')")
                                                        v-icon add
                                                        | {{ $t('Agregar correo electrónico') }}
                                                    br
                                        h3.mb-2.mt-4 {{ $t('Encuesta') }}
                                        custom-form(
                                            ref="customForm"
                                            :visibility="false", 
                                            :m-custom.sync="model.custom",
                                            :m-attribute.sync="model.attribute",
                                            custom-form-title='Campos de encuesta'
                                            :image-selection="true"
                                            :element-detail="true"
                                            :scale-selection="true"
                                            :limit-multiple-selection="true"
                                            :show-other="true"
                                            @error="customFormError"
                                        )
                                v-card-actions(v-if="!loadModal")
                                    .btns.btns-right
                                        v-btn(color="secondary" small @click="modal = false") {{ $t('Cancelar') }}
                                        v-btn(:loading="loading" color="primary" large @click.stop="save") {{ $t('Guardar') }}
                    v-dialog(v-model="shareModal" max-width="600")
                        v-form
                            v-card
                                v-card-title {{ $t('Compartir encuesta') }}
                                v-card-text
                                    p {{ $t('Comparte esta encuesta a través de tus redes sociales') }}
                                    br
                                    .btns.btns-center
                                        v-btn.btn-whatsapp(@click.native="shareWp" large color="secondary light--text") 
                                            img(src="/public/img/icons/whatsapp-icon.svg" :alt="$t('Whatsapp')")
                                            | {{ $t('Compartir en whatsapp') }}
                                        v-btn.btn-facebook(@click.native="shareFb" small color="secondary light--text") 
                                            img(src="/public/img/icons/facebook-icon.svg" :alt="$t('Facebook')")
                                            | {{ $t('Compartir en facebook') }}
                                        v-btn.btn-twitter(@click.native="shareTw" small color="secondary light--text") 
                                            img(src="/public/img/icons/twitter-icon.svg" :alt="$t('Twitter')")
                                            | {{ $t('Compartir en twitter') }}
                                        br
                                        a.mt-4(target="blank" :href="surveyurl"): b {{ surveyurl }}
                                v-card-actions
                                    .btns.btns-right
                                        v-btn(color="secondary" small @click.stop="shareModal = false") {{ $t('Cancelar') }}
                v-dialog(v-model="importModal" max-width="1024" persistent)
                        v-form
                            v-card
                                v-card-title {{ $t('Importar contactos') }}
                                v-card-text
                                    p {{ $t('Importar contactos para la encuesta') }} {{ importModel.name }}
                                    v-btn.primary(@click="downloadTemplate") {{ $t('Descargar plantilla') }}
                                    h3.mt-4 {{ $t('Importar usuarios de la red') }}
                                    v-layout(row wrap v-if="importPending && importPendingData.total != importPendingData.totalSuccess")
                                        v-flex(xs12)
                                            h4 {{ $t('Importando usuarios') }}: {{ importPendingData.totalSuccess }} {{ $t('de') }} {{ importPendingData.total }}
                                    v-layout(row wrap)
                                        v-flex(xs12)
                                            v-select.mb-3(
                                                v-model="importNetwork"
                                                :items='networks'
                                                item-text="name"
                                                item-value="uuid"
                                                :label='$t("Seleccione la red a importar")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilter"
                                                clearable
                                            )
                                        v-flex(xs12)
                                            v-btn.primary(@click="importFromNetwork" :loading="loadingBtnNetwork") {{ $t('Importar usuarios de la red') }}
                                    h3.mt-4 {{ $t('Descargar usuarios importados') }}
                                        v-flex(xs12)
                                            v-select.mb-3(
                                                v-model="exportNetwork"
                                                :items='networks'
                                                item-text="name"
                                                item-value="uuid"
                                                :label='$t("Seleccione la red a exportar")'
                                                append-icon="keyboard_arrow_down" 
                                                autocomplete
                                                :filter="vFilter"
                                                clearable
                                            )
                                        v-flex(xs12)
                                            v-btn.primary(@click="downloadImported(exportNetwork)" :loading="loadingBtn") {{ $t('Descargar usuarios importados') }}
                                    h4.mt-4 {{ $t('Nombre de los campos') }}
                                    v-data-table(:headers='headers' :items="items" hide-actions)
                                        template(slot='headers' slot-scope='props')
                                            tr
                                                th(v-for='(header, i) in props.headers' :key='i' :align="header.align")
                                                    span.subheading {{ $t(header.text) }}
                                        template(slot='items' slot-scope='props')
                                            tr
                                                td {{ props.item.id }}
                                                td {{ props.item.name }}
                                                td {{ props.item.required }}
                                                td {{ props.item.instructions }}
                                    h4.mt-4.mb-2 {{$t('Subir archivo')}}
                                    input(type='file' @change="fileChangeImport")
                                v-card-actions
                                    .btns.btns-right
                                        v-btn(color="secondary" small @click.stop="importModal = false") {{ $t('Cancelar') }}
                                        v-btn(:loading="loadingImport" color="primary" large @click.stop="importSave") {{ $t('Importar') }}
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'

    export default {
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Encuestas')
            }
        },
        data() {
            return {
                preload: false,
                pagination: {},
                drawerRight: false,
                modalDateStart: false,
                modalDateEnd: false,
                filter: {},
                filterSchema: {
                    query    : '',
                    network  : '',
                    from     : null,
                    to       : null
                },
                surveys  : [],
                networks : [],
                loading: false,
                surveySchema: {
                    uuid        : '',
                    networkid   : '',
                    alias       : '',
                    name        : '',
                    terms       : ' ',
                    priv        : ' ',
                    status      : false,
                    custom      : [],
                    attribute   : [],
                    shareImg    : '',
                    share       : '',
                    availability: [],
                    starttext   : '',
                    endtext     : '',
                    wpconfirm   : false,
                    whatsapp    : '',
                    recaptcha   : false,
                    email       : []
                },
                model: {},
                alertMsj  : false,
                alertColor: '',
                alertText : '',
                modal: false,
                loadModal: false,
                limit : this.$root.limit,
                offset: 0,
                shareModal: false,
                shareModel: {},
                importModal: false,
                importModel: {},
                importFile: '',
                headers: [
                    {
                        text: 'ID',
                        align: 'left',
                        sortable: false
                    },
                    {
                        text: 'Nombre(s)',
                        align: 'left',
                        sortable: false
                    },
                    {
                        text: 'Requerido',
                        align: 'left',
                        sortable: false
                    },
                    {
                        text: 'Instrucciones',
                        align: 'left',
                        sortable: false
                    }
                ],
                loadingImport: false,
                loadingBtn: false,
                node: [],
                tree: {
                    multiple: true,
                    checkbox: true,
                    checkOnSelect:true,
                    parentSelect: true
                },
                loadingBtnNetwork: false,
                importPendingData: {
                    total: 0,
                    totalSuccess: 0
                },
                importNetwork: '',
                exportNetwork: ''
            }
        },
        computed: {
            profile(){
                return this.$root.profile
            },
            surveyurl() {
                if (this.currentNetwork.custom_domain) {
                    return `https://${this.currentNetwork.custom_domain}/e/${this.shareModel.alias}`
                }
                return `https://${this.shareModel.network_alias}.wappid.com/e/${this.shareModel.alias}`
            },
            imgurl(){
                return config.imgurl
            },
            uploadurl(){
                return config.uploadurl
            },
            items(){
                return [
                    {
                        id      : 'names',
                        name    : this.$t('Nombre(s)'),
                        required: this.$t('Si'),
                        instructions: ''
                    },
                    {
                        id      : 'email',
                        name    : this.$t('Correo electrónico'),
                        required: this.$t('Si'),
                        instructions: ''
                    },
                    {
                        id      : 'phone',
                        name    : this.$t('Teléfono'),
                        required: this.$t('Si'),
                        instructions: ''
                    },
                    {
                        id      : 'country',
                        name    : this.$t('País'),
                        required: this.$t('No'),
                        instructions: this.$t('Nombre del país, exactamente igual que en listado de países')
                    },
                    {
                        id      : 'state',
                        name    : this.$t('Estado/provincia'),
                        required: this.$t('No'),
                        instructions: this.$t('Nombre del estado/provincia, exactamente igual que en listado de estados')
                    },
                    {
                        id      : 'city',
                        name    : this.$t('Ciudad'),
                        required: this.$t('No'),
                        instructions: this.$t('Nombre de la ciudad, exactamente igual que en listado de ciudades')
                    },
                    {
                        id      : 'gender',
                        name    : this.$t('Género'),
                        required: this.$t('No'),
                        instructions: this.$t('0=Femenino, 1=Masculino, 2=Otro')
                    },
                    {
                        id      : 'birthdate',
                        name    : this.$t('Fecha de nacimiento'),
                        required: this.$t('No'),
                        instructions: this.$t('Fecha AAAA-MM-DD en tipo texto')
                    },
                    {
                        id      : 'document',
                        name    : this.$t('Documento de identificación'),
                        required: this.$t('No'),
                        instructions: ''
                    }
                ]
            },
            importPending(){

                if(typeof this.importPendingData == 'object' && this.importPendingData.total > 0){
                    return true;
                }

                return false
            }
        },
        watch: {
            filter: {
                handler(){
                    this.fetchSurvey()
                },
                deep: true
            },
            profile: {
                handler(){
                    this.fetchNetworks()
                },
                deep: true
            }
        },
        created(){
            this.model  = this._.cloneDeep(this.surveySchema)
            this.filter = this._.cloneDeep(this.filterSchema)
        },
        methods: {

            importFromNetwork(){

                this.loadingBtnNetwork =  true

                let filter = this.$qs.stringify({
                    networkid : this.importNetwork,
                    surveyid  : this.importModel.uuid
                })

                this.$api(this, (xhr) => {
                    xhr.post('/survey/import-contact', filter).then((r) => {

                        let data = r.data
                        this.loadingBtnNetwork = false
                        this.alertMsj = true

                        if(data.response){
                            this.alertColor = 'success'
                        }else{
                            this.alertColor = 'error'
                        }

                        this.alertText = data.message
                        this.importNetwork = ''

                    }).catch(() => {
                        this.loadingBtnNetwork = false
                        this.importNetwork = ''
                    })
                })
            },

            fetchImportNetworStatus(){

                this.importPendingData = {}

                this.$api(this, (xhr) => {
                    xhr.get('/survey/import-contact', {
                        params: {
                            networkid: this.importModel.networkid,
                            surveyid : this.importModel.uuid
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.importPendingData = data.data
                        }

                    }).catch(() => {})
                })
            },

            downloadImported(networkid){

                this.loadingBtn =  true
                let userid = this.$root.profile.uuid

                let filter = this.$qs.stringify({
                    filter  : JSON.stringify(this.filter),
                    networkid,
                    surveyid: this.importModel.uuid
                })

                this.$api(this, (xhr) => {
                    xhr.post('/survey/uploaded-contact', filter).then((r) => {

                        let data = r.data
                        this.loadingBtn = false

                        if(data.response){
                            let url = config.apiurl + 'download?id=' + userid + '&file=' + data.data
                            window.location.href = url
                        }
                    }).catch(() => {})
                })
            },

            downloadTemplate(){
                window.open(
                    config.apiurl + 'import_survey_contact.xlsx',
                    '_blank'
                )
            },

            openImport(data){

                this.importFile  = ''
                this.importModel = this._.cloneDeep(data)
                this.importModal = true

                this.fetchImportNetworStatus()
            },

            customFormError(msj){
                this.alertMsj   = true
                this.alertColor = 'error'
                this.alertText  = msj
            },
            
            shareFb(uuid){
                window.open(
                    'https://www.facebook.com/sharer/sharer.php?u=' + window.encodeURIComponent(this.surveyurl), 
                    'popup', 
                    'width=400, height=300'
                )
            },
            shareTw(uuid){
                window.open(
                    'https://twitter.com/intent/tweet?text=' + window.encodeURIComponent(this.shareModel.name + ' ' + this.surveyurl), 
                    'popup',
                    'width=400, height=300'
                )
            },
            shareWp(uuid){
                let url = this.isMobile() ? 'whatsapp://' : 'https://api.whatsapp.com/'
                window.open(
                    url+'send?text=' + window.encodeURIComponent(this.shareModel.name + ' ' + this.surveyurl),
                    'popup'
                )
            },

            fetchSurvey(infinite = false){

                if(infinite){
                    this.offset += this.limit
                }else{
                    this.preload = true
                    this.surveys = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                let filter = {
                    limit : this.limit,
                    offset: this.offset,
                    filter: JSON.stringify(this.filter)
                }

                this.$api(this, (xhr) => {
                    xhr.get('/survey', { params: filter }).then((r) => {

                        if(!infinite) this.preload = false
                        let data = r.data

                        if(infinite){
                            if(data.response){
                                this.surveys = this.surveys.concat(data.data)
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        }else{
                            this.surveys = (data.response ? data.data : [])
                        }
                    }).catch(() => {})
                })
            },

            infinite(){
                setTimeout(() => this.fetchSurvey(true) , 500)
            },

            fetchNetworks(){

                let url = '/network?list=1'
                if(this.$root.profile.type == 2){
                    url = '/network/profile?list=1'
                }

                this.$api(this, (xhr) => {
                    xhr.get(url).then((r) => {

                        let data = r.data
                        if(data.response){
                            let networks  = [[]].concat(data.data) 
                            this.networks = networks
                        }
                    }).catch(() => {})
                })
            },

            cleanFilter(){
                this.filter = this._.cloneDeep(this.filterSchema)
            },

            async open(data){

                this.model = this._.merge(this._.cloneDeep(this.surveySchema), this._.cloneDeep(data))
                this.model.share = this.model.share == null ? '' : this.model.share

                this.loading = false
                this.modal  = true

                this.loadModal = true
                await this.fetchForm()
                await this.fetchCountry()
                this.loadModal = false
            },

            checkNode(node){

                let found = []
                if(this.model.hasOwnProperty('availability')){
                    found = this.model.availability.filter((ab) => {
                        return node.data.uuid == ab.countryid
                    })
                }

                if(found.length){
                    node.state = { checked: true }
                }

                return node
            },

            fetchCountry(){

                return new Promise((resolve) => {

                    let params = {
                        filter: JSON.stringify({
                            status: 1
                        })
                    }

                    this.$api(this, (xhr) => {
                        xhr.get('/country', {params}).then((r) => {
                            let data = r.data
                            if(data.response){

                                let node = data.data
                                node = node.map((node) => {

                                    return {
                                        text: node.name,
                                        data: node
                                    }
                                })

                                this.node = node.map(this.checkNode)
                                resolve()
                            }
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchForm(){
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/survey/form', {
                            params: {
                                networkid : this.model.networkid || '',
                                survey    : this.model.uuid || ''
                            }
                        }).then((r) => {

                            let data  = r.data
                            let model = data.data

                            if(data.response){

                                model.custom = model.custom.map((c) => {
                                    c.required   = c.required == 1 ? true : false
                                    c.visibility = c.visibility == 1 ? true : false
                                    c.options    = this.repairOptions(c.options)
                                    return c
                                })

                                this.model.custom    = model.custom
                                this.model.attribute = model.attribute
                            }
                            
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            openShare(data){
                this.shareModel = this._.cloneDeep(data)
                this.shareModal = true
            },

            importSave(){

                let post = new FormData()
                
                post.append('upload', this.importFile)
                post.append('networkid', this.importModel.networkid)

                this.loadingImport = true
                this.$api(this, (xhr) => {
                    xhr.post('/survey/upload-contact', post).then((r) => {

                        let data = r.data
                        this.loadingImport = false
                        this.alertMsj = true

                        if(data.response){
                            this.alertColor = 'success'
                            this.alertText = data.message
                            this.importModal = false
                        }else{
                            this.alertColor = 'error'
                            this.alertText = data.message
                        }
                    }).catch(() => {
                        this.loadingImport = false
                    })
                })
            },

            save(){

                let post = this._.cloneDeep(this.model)
                post.status    = post.status ? 1 : 0
                post.wpconfirm = post.wpconfirm ? 1 : 0
                post.recaptcha = post.recaptcha ? 1 : 0
                post.email     = JSON.stringify(post.email)

                let nodes = this.$refs.treeNode.findAll({})
                nodes = nodes == null ? [] : nodes
                nodes = nodes.filter((node) => {
                    return node.checked()
                })

                nodes = nodes.map((node) => {
                    return {
                        countryid : node.data.uuid
                    }
                })

                post.availability = JSON.stringify(nodes)
                post.custom = JSON.stringify(post.custom.map((f) => {

                    f.required   = f.required ? 1 : 0,
                    f.visibility = f.visibility ? 1 : 0
                    f.multiple = f.multiple ? 1 : 0

                    // Agregar imagen en descripción
                    if (f.hasOwnProperty('image')) {
                        post['file-' + f.uuid] = f.image
                        delete f.image
                            
                        if(f.preview.includes('data:image')){
                            delete f.preview
                        }
                    }

                    if(f.type == 6){

                        if(!f.depends){
                            f.options = f.options.map((opt) => {

                                if(opt.hasOwnProperty('image')){
                                    post['file-' + opt.uuid] = opt.image
                                    delete opt.image
                                }

                                if(opt.preview.includes('data:image')){
                                    delete opt.preview
                                }
                                
                                return opt
                            })

                        }else{
                            for(let option in f.options){
                                f.options[option] = f.options[option].map((opt) => {
                                    if(opt.hasOwnProperty('image')){
                                        post['file-' + opt.uuid] = opt.image
                                        delete opt.image
                                    }

                                    if(opt.preview.includes('data:image')){
                                        delete opt.preview
                                    }
                                    return opt
                                })
                            }
                        }
                    }

                    return f
                }))

                post.attribute = JSON.stringify(post.attribute.map((f) => {
                    
                    f.isimage = f.isimage ? 1 : 0
                    f.options = f.options.map((opt) => {
                        if(f.isimage != undefined && f.isimage){

                            if(opt.hasOwnProperty('image')){
                                post['file-' + opt.uuid] = opt.image
                                delete opt.image
                            }

                            if(opt.preview.includes('data:image')){
                                delete opt.preview
                            }
                        }

                        return opt
                    })

                    return f
                }))

                let form = new FormData()
                for(let i in post){
                    form.append(i, post[i])
                }

                this.loading = true
                this.$api(this, (xhr) => {
                    xhr.post('/survey', form).then((r) => {

                        let data = r.data
                        this.loading = false
                        this.alertMsj = true

                        if(data.response){
                            this.alertColor = 'success'
                            this.alertText = data.message
                            this.modal = false
                            this.fetchSurvey()
                        }else{
                            this.alertColor = 'error'
                            this.alertText = data.message
                        }
                    }).catch(() => {
                        this.loading = false
                    })
                })
            },

            fileChangeImport(e) {

                var files = e.target.files || e.dataTransfer.files
                if (!files.length) return

                this.importFile = files[0]
            },

            fileChange(e, model, save) {

                var files = e.target.files || e.dataTransfer.files
                if (!files.length) return
                this.createImage(files[0], model, save)
            },

            createImage(file, model, save) {

                let reader = new FileReader();
                reader.onload = (e) => this.model[model] = e.target.result
                reader.readAsDataURL(file)
                this.model[save] = file
            },

            removeImage(model, save) {
                this.model[model] = ''
                this.model[save] = ''
            },

            repairOptions(options){

                for(let i in options){
                    let opt = options[i]
                    if(typeof opt == 'string'){
                        options[i] = {
                            uuid  : this.$uuid(),
                            option: opt,
                            depend: ''
                        }
                    }
                }
                return options
            },

            selectAll(){
                let tree = this.$refs.treeNode.findAll({})
                tree.check(true)
            },

            unselectAll(){
                let tree = this.$refs.treeNode.findAll({})
                tree.uncheck(true)
            },

            deleteEmail(i){
                this.model.email.splice(i, 1)
            }
        }
    }
    
</script>